button {
  background-color: #72aa52;
  color: #000;
}

/* .ticket-button {
  width: 173px;
  height: 45p;
} */

.ticket-button {
  /* width: 100%; */
  padding: 1rem 2rem;
  font-size: var(--font-size-tiny);
  background-color: var(--orange);
  color: #ffffff;
  border-radius: 3px;
  border: none;
  text-align: center;
}

button.ticket-button:disabled {
  background: #dddddd;
  color: #333;
}

.light-btn {
  padding: 1rem 2rem;
  font-size: var(--font-size-tiny);
  background-color: #ffffff;
  color: var(--orange) !important;
  border-radius: 3px;
  border: 1px solid var(--orange);
  text-align: center;
}

.default-button {
  /* width: 100%; */
  padding: 1rem 3rem;
  font-size: var(--font-size-tiny);
  background-color: var(--orange);
  color: #ffffff;
  border-radius: 3px;
  border: none;
}

.voucher-btn{
  border: 1px solid transparent;
  background: transparent;
  text-align: center;
  color: #007bff;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  outline: none;
  float: right;
}

.voucher-msg{
  color: green;
  float: right;
}

.voucher-btn:hover{
  color: #0056b3;
  text-decoration: underline;
}
