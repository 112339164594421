.input-group {
  /* margin-bottom: 1.6rem; */
}

.input-group p {
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="text"]:focus {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  padding: 1.3rem;
  font-size: 1.5rem;
  background: #f8f8f8;
  /* box-shadow: none !important; */
  border: 1px solid #ccc !important;
}

.custom-select,
.custom-select:focus {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  outline: none;
  font-size: 1.7rem;
  border: 1px solid #ccc !important;
}

.block-btn {
  width: 100%;
  /* font-size: var(--font-size-medium); */
}
