/* @import url("reset"); */
@import url("./fonts.css");
@import url("./buttons.css");
@import url("./input.css");

:root {
  /* Font Families */
  --regular: "BioSansRegular";
  --bold: "BioSansBold";
  --extrabold: "BioSansExtraBold";
  --light: "BioSansLight";
  --extralight: "BioSansExtraLight";

  /* Font Sizes */
  --font-size-huge: 5rem;
  --font-size-large: 4rem;
  --font-size-average: 2.5rem;
  --font-size-medium: 2rem;
  --font-size-small: 1.6rem;
  --font-size-tiny: 1.4rem;
  --font-size-very-tiny: 1.2rem;

  /* Colors */
  --orange: #72aa52;
}
html {
  font-size: 62.5%;
}

body {
  /* background: url("/././assets/images/ad-bg.png"); */
  background-color: #020d18;
  color: #fff;
  padding: 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: var(--regular);
}

h1 {
  font-size: 4rem;
  font-weight: 600;
}

#myCarousel {
  /* z-index: -10; */
}

.content-pad {
  margin-left: 30px;
}

.header {
  background-image: linear-gradient(rgba(2, 13, 24, 0), #000000);
  height: calc(100vh-200px);
  background-size: cover;
  background-position: top;
}

/** CAROUSEL **/
/* .carousel-item > img::after {
  background: linear-gradient(rgba(2, 13, 24, 0), #000000);
} */
.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 600px) {
  .carousel-item {
    height: auto;
    padding-top: 10rem;
  }

  .container .section-separator .featured-container {
    margin-top: 0;
    margin-bottom: 0;
    display: none;
  }
}

.carousel-indicators {
  bottom: 10rem;
}

.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  margin-right: 8px;
}

.carousel-indicators li.active {
  background-color: var(--orange);
}

.trailer-span {
  margin-right: 2rem;
}

.iconimg {
}

.carousel-item,
.item img:not(".iconimg") {
  height: 100vh !important;
  object-fit: cover !important;
}

.carousel-item img:after {
  background: linear-gradient(rgba(2, 13, 24, 0), #000000);
}

.bg-dark {
  background-color: #020e18 !important;
}

.carousel-caption {
  right: 48%;
  bottom: 40%;
  left: 11.5%;
}

.nav {
  /* display: flex; */
  justify-content: space-between;
  height: 82px;
  align-items: center;
  padding: var(--site-padding);
}

.nav-menu {
  flex-grow: 0;
}

.nav-menu ul li {
  list-style: none;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: normal;
}

.nav-menu ul li:not(:last-child) {
  padding-right: 15px;
}

.nav__search {
  width: 400px;
}

.nav__search .nav__search-input,
.nav__search .nav__search-input:focus {
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.45);
  padding-left: 3rem;
  font-size: 14px;
}

body input.nav__search-input,
.nav__search .nav__search-input:focus {
  border: 0px !important;
  font-size: 16px !important;
  color: #fff !important;
}

input.nav__search-input::placeholder {
  color: #ffffff;
}

/* .nav__search .nav__search-input:focus {
  border-radius: 40px;
} */

.header__nav {
  /* background-color: rgba(114, 170, 82, 0.548); */
  background-color: #000000;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

body .navbar-dark .navbar-toggler {
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 0;
  margin-top: 20px;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.menu-ticket {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
}

.menu-ticket__count {
  display: flex;
  flex-direction: column;
  margin: 0 1.5rem;
  font-size: var(--font-size-tiny);
}

.menu-ticket__person img {
  /* width: 70%; */
}

.menu-ticket__dropdown {
  font-size: 1.3rem;
  width: 250px;
}

.dropdown-menu-right {
  right: 10px;
}

.days-tab {
}

.showing-span {
  float: left;
  margin-right: 2rem;
}

.showing-days {
  float: right;
}

.days-tab span {
  margin-right: 2.5rem;
}

@media (max-width: 1200px) {
  .day-right {
    margin-top: 20px;
  }
}

.days-tab a {
  margin-right: 2rem;
}
.days-tab a:first-child {
  margin-left: 2rem;
}

.days-tab a.active:after {
  content: "";
}

.top-search__input {
  position: absolute;
  background: rgba(255, 255, 255, 0.45);
  border-radius: 40px;
  height: 45px;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
}

.caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24vh;
  max-width: 560px;

  position: absolute;
  z-index: 9;
  top: 0;
}

/** Caption  **/
.caption__tags {
  text-transform: uppercase;
  font-size: 14px;
}

.caption__tags ul {
  padding-inline-start: 0px;
}

.caption__tags ul li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
  border-radius: 40px;
  border: 1px solid #72aa52;
  padding: 5px 15px;
  font-size: var(--font-size-tiny);
  font-weight: 100;
}

.caption__title {
  /* margin-bottom: */
}

.caption__synopsis {
  /* font-size: var(--font-size-small); */
  font-family: "BioSansLight";
  line-height: 29px;
  padding-right: 10px;
}

.caption__action-call {
  margin-top: 3rem;
}
.centered-title {
  justify-content: center;
}
.section-title {
  display: flex;

  font-size: var(--font-size-medium);
  font-weight: 400;
  /* margin-bottom: 30px; */
  /* padding-bottom: 10px; */
  position: relative;
  border-bottom: 0.8px solid #3c3c3c;

  width: 100%;
}

.section-title__no-underline {
  display: flex;
  justify-content: space-around;
  font-size: var(--font-size-medium);
}

/* .section-title:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 6px;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid var(--orange);
} */

.filter-section {
  display: flex;
}

.filter-section .form-group {
  margin-right: 2rem;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.filter-section .form-control {
  display: inline;
}

.overlay {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.overlay-static-page {
  background: linear-gradient(
    180deg,
    rgba(2, 13, 24, 0) 0%,
    rgba(2, 13, 24, 0.0614001) 0%,
    rgba(2, 13, 24, 0.22471) 0%,
    rgba(2, 13, 24, 0.315373) 18.7%,
    #020d18 103.5%
  );
}

.carouselImage:after {
  background: linear-gradient(
    180deg,
    rgba(2, 13, 24, 0) 0%,
    rgba(2, 13, 24, 0.0614001) 0%,
    rgba(2, 13, 24, 0.22471) 0%,
    rgba(2, 13, 24, 0.315373) 95.7%,
    #020d18 103.5%
  );
}

.item > img,
.item > a > img {
  height: 100vh !important;
}

.y-buyticket-form,
.y-buyticket-details {
  margin-top: 20px;
}

/* Featured */
.featured-container {
}

.featured {
  /* margin-top: -120px; */
  margin-top: -20vh;
  margin-bottom: 40px;
}

.featured_item {
  height: 380px;
  position: relative;
  /* background-color: white; */
  border-radius: 5px;
  /* padding: 0; */
}

.item {
  margin: 0;
}

.feat_item {
  margin: 0 10px;
}

.featured_item img {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.featured-slides {
  margin-top: -120px;
  position: relative;
  z-index: 2;
}
.movies-section {
  padding-left: 30px;
  padding-right: 30px;
}

.section-separator {
  margin-top: 40px;
  margin-bottom: 50px;
}

.overlay-card {
  position: relative;
  height: 100%;
}

.overlay-card1 {
  position: relative;
  height: 100%;
}

.youtube {
  max-height: calc(100% - 100px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(2, 13, 24, 0.74);
  border-radius: 5px;
}

.middle1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background: rgba(2, 13, 24, 0.74);
  border-radius: 5px;
}

.bg1,
.bg2,
.play {
  transition: 0.5s ease;
}

.featured-ticket:hover .bg1,
.featured-ticket:hover .bg2 {
  fill: #fff;
}

.featured-ticket:hover .play {
  fill: #72aa52;
}

.featured-ticket {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-card:hover .middle {
  opacity: 1;
}

/* Movies + Cards */
.movies__card {
  /* border: 1px solid #72AA52; */
  border-radius: 5px;
  height: 313px;
  background-color: inherit;
  margin-bottom: 25px;
}

.movies__card-image {
  width: 50%;
  /* border-right: 1px solid var(--orange); */
  height: 313px;
  float: left;
  padding: 0;
}

.movies__card-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 100%;
  height: 313px;
  border-radius: 5px 0 0 5px;
}

.movies__card-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  float: right;
  color: white;
  height: 313px;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  border-top: 1px solid #3c3c3c;
  border-right: 1px solid #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
}

.movie-name {
  font-size: var(--font-size-small);
  /* font-weight: 400; */
  font-family: var(--bold);
  text-transform: capitalize;
}

.movie-genre {
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 2rem;
}

.movie-showing {
  margin-bottom: 2rem;
  font-size: var(--font-size-very-tiny);
  font-family: var(--regular);

  margin-bottom: 2rem;
  display: block;
  display: -webkit-box;
  max-width: initial;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.foot {
  position: fixed;
  /* bottom: 50px; */
  padding: 20px 0;
  font-size: 36px;
  transition: all 0.2s;
  z-index: 1000001;
  left: 0;
  right: 0;
  /* center all inline content */
  text-align: center;
}

.foot > img {
  display: inline-block;
  text-align: left;
}
.foot .footclose {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 15px;
  font-weight: light;
  text-decoration: none;
  color: black;
  transition: all 0.3s;
  font-weight: 600;
  margin-top: -20px;
}
.foot .footclose:hover {
  color: white;
}
.foot .footclose:active {
  color: #ff99ff;
}

.scrolled {
  bottom: 0px;
}

.gone {
  transition: all 300;
  opacity: 0;
}

.movie-cast {
  margin-bottom: 2rem;
  font-size: var(--font-size-very-tiny);
  font-family: var(--regular);

  margin-bottom: 2rem;
  display: block;
  display: -webkit-box;
  max-width: initial;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-casting {
  margin-bottom: 2rem;
  font-size: var(--font-size-very-tiny);
  font-family: var(--regular);

  margin-bottom: 2rem;
  display: block;
  display: -webkit-box;
  max-width: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/** Home tab */
.movies-tab {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 10px;
  align-items: center;
}

.movies-tab a {
  color: #ffffff;
}

.movies-tab a.active,
.movies-tab a:hover {
  color: #72aa52;
}

.movies-tab a.nav-link:first-child {
  padding-left: 0;
}

.active:after {
  display: none;
}

.movies-tab a.nav-link.active:after {
  display: none !important;
  content: none !important;
}

.nav-item > .nav-link.dropdown-toggle {
  text-align: center;
  display: flex;
  align-items: center;
}

.dropdown-menu a {
  color: #212529 !important;
}

.days-tab .filter-section,
.days-tab .filter-section .form-group:last-child {
  margin-right: 0;
}

.filter-section .form-group select {
  background-color: #020d18;
  color: #ffffff;
  border-left: none;
  box-shadow: none;
  font-size: 1.4rem;
  border-top: none;
  border-right: none;
  border-radius: 0 !important;
  text-align: center;
}

.tab-content .fade {
  -webkit-transition: opacity 2s linear !important;
  transition: opacity 2s linear !important;
}

.featured_item img.comingsoon_image {
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
  height: 340px;
}

/* ADVERT */
.advert {
  display: flex;
  align-items: center;
  background: url("https://res.cloudinary.com/dwa8k0pds/image/upload/v1560942595/ad-bg.png");
  padding-top: 60px;
  padding-bottom: 60px;
}

.ad-image {
  height: 356px;
}

.advert img {
  width: 100%;
  height: 356px;
}

.timer-thing {
  font-size: 150%;
  color: #74aa50;
  font-weight: 600;
}

.ad-title {
  font-size: var(--font-size-large);
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 2rem;
}

.ad-profile {
  padding: 3rem;
  font-size: var(--font-size-small);
  line-height: 1.4;
  margin: auto 0;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start; */
}

.ad-description {
  margin-bottom: 2rem;
  font-family: var(--regular);
}

/* NEWS & EVENTS */
.news {
  margin-bottom: 40px;
}

.news_item {
  height: 380px;
  /* background-color: white; */
  border-radius: 5px;
  /* padding: 0; */
  color: #000000;
}

.news__content {
  text-align: left;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  background-color: #ffffff;
  height: 220px;
  border-radius: 0 0 5px 5px;
}

.news__placeholder {
  height: 160px;
  background: white;
  padding-top: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.news__content-title {
  font-size: var(--font-size-small);
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.news__content-synopsis {
  font-size: var(--font-size-tiny);
  font-weight: 300;
  margin-bottom: 1rem;
}

.news__content-date {
  font-size: var(--font-size-tiny);
  color: #53627c;
}

.news__placeholder img {
  width: 99%;
  height: 160px;
  border-radius: 5px 5px 0 0;
}

/** SINGLE MOVIE **/
.singlemovie-header {
  height: 40vh;
  /* background-color: white; */
  /* margin-bottom: 6rem; */
}

.singlemovie-header img {
  object-fit: cover;
  height: 50vh;
  /* margin-top: 2rem; */
}

.singlemovie__slider {
  position: absolute;
}

.movie__thumbnail {
  height: 300px;
  border-radius: 5px;
}

.movie__thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.movie__title {
  font-weight: 800;
  text-transform: capitalize;
}

.movie__others {
  /* height: 300px; */
  object-fit: contain;
  border-radius: 5px;
}

.movie__others img {
  width: 100%;
  /* height: 300px; */
  border-radius: 5px;
}

.movie__ticket-form {
  background-color: #ffffff;
  border-radius: 4px;
  color: #000000;
  padding: 2rem;
}

.form-check label.form-check-label {
  margin-left: 1rem;
}

.form-check {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.movie__total {
  display: flex;
  justify-content: space-between;
  margin: 1.6rem 0;
}

.y-to-home-div .ticket-button {
  color: #ffffff !important;
}

.ticket-buyer__radio {
  margin-bottom: -20px;
  margin-top: 20px;
}

.ticket-buyer__radio span:not(:last-child) {
  margin-right: 15px;
}

.ticket-buyer__form {
  border: 1px solid rgba(255, 255, 255, 0.23);
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 3rem;
}

.ticket-buyer__type {
  font-size: var(--font-size-small);
  font-weight: 600;
}

.ticket-buyer__details p:not(:first-child) {
  margin-bottom: -0.2rem;
}

.ticket-buyer__form .form-group {
  font-size: var(--font-size-tiny);
}

.ticket-buyer__expiry {
  text-transform: uppercase;
  font-size: var(--font-size-medium);
}

.ticket-buyer__time {
  font-size: var(--font-size-large);
}

.movie__ticket-summary {
  background-color: #ffffff;
  border-radius: 4px;
  color: #000000;
  padding: 2rem;
}

.movie__ticket-summary > div > p {
  font-size: var(--font-size-medium);
}

.movie__ticket-summary > div > p::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #6a6a6a;
  margin-bottom: 10px;
}

.ticket-md-subscription {
  float: left;
}

.buy-ticket-md-section {
  float: right;
}

.buy-ticket_type {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ticket-type_name {
  font-size: var(--font-size-very-tiny);
  color: #6a6a6a;
}

.ticket__summary-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: var(--font-size-tiny);
}

.table-heading {
  font-size: var(--font-size-small);
  font-weight: 500;
  color: #000000 !important;
}

.capitalize {
  text-transform: capitalize;
}

.table_col_one {
  width: 60%;
}

.table_col_two {
  width: 15%;
  float: left;
}

.table_col_three {
  width: 25%;
  text-align: right;
}

.total,
.price {
  font-weight: 600;
  font-size: 1.8rem;
}

.naira-sign {
  text-decoration-style: double;
}

.ticket-type {
  color: #6a6a6a;
  text-transform: capitalize;
}

/* PRICING CARD */
.pricing-section {
  margin-top: -100px;
}

.pricing-card {
  background-color: #ffffff;
  height: 100%;
  border-radius: 5px;
  color: black;
  padding: 4rem 1rem;
  margin-top: 1rem;
}

.pricing-btn {
  position: absolute;
  margin-left: -50px;
  bottom: 0;
  margin-bottom: 2rem;
}

.pricing-card__description {
  display: inline-block;
  margin-bottom: 4rem;
  border-top: 1px solid #dcdcdc;
}

.pricing-card__description ul li {
  margin: 1rem 0;
}

.price-num {
  font-size: var(--font-size-large);
}

.price-mth {
  font-size: var(--font-size-tiny);
  color: #6d6d6d;
}

/* MOVIE SUBSCRIPTION */
.moviesub {
  padding: 30px;
}

.moviesub_steps ul li {
  list-style: none;
  display: inline-block;
  width: 33%;
  text-align: center;
  vertical-align: top;
  padding: 1rem;
}

.moviesub_steps ul li h3 {
  font-weight: 800;
  font-size: var(--font-size-medium);
}

.moviesub_steps ul li:before {
  content: "";
  position: absolute;
  width: 0;
  height: 40px;
  margin-top: -4rem;
  /* background: url(../images/icons/how.png); */
}

/*  LOGIN */

.login_container {
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 100vh;
  color: #000000;
  background: #fcfffb;
}

.login__form {
  background-color: #ffffff;
  padding: 6rem 6rem;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}

.login__form a,
.login__form a:visited,
.login__form a:link,
.login__form a:hover,
.register__form a,
.register__form a:visited,
.register__form a:link,
.register__form a:hover {
  color: var(--orange);
  text-decoration: none;
}

.login__form a:hover {
  color: var(--orange);
}

.login__form-input {
  margin: 1rem 0;
}

.login__form > div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.welcome-text {
  font-size: var(--font-size-medium);
  font-family: var(--bold);
}

.sorry-text {
  font-size: 3rem;
  font-weight: bold;
  width: 25rem;
}

.successful-text {
  font-size: 3rem;
  font-weight: bold;
}

.signup-text {
  font-family: var(--light);
  font-size: var(--font-size-tiny);
}

.login__lead {
  font-family: var(--bold);
  font-size: var(--font-size-medium);
}

.forgot_password {
  float: right;
}

.other-m {
  margin: 70px 15px 30px 15px;
}

.login__side {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login__side > div,
.login__side-content > div,
img {
  /* margin: 1rem auto; */
}

.login__side:nth-child(1) {
  font-weight: var(--extrabold);
}

.login__side-content {
  width: 70%;
  text-align: center;
}

span.input-group-btn#minus,
span.input-group-btn#add {
  width: 45px;
  border: 1px Solid #c3c3c3;
}

.green-color {
  color: var(--orange);
}

.price-card {
  background: #fbfbfb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.price-card .sub-price {
  font-size: var(--font-size-average);
}

.price-card__icon {
  align-items: center;
  display: flex;
}

.price-card__icon img {
  width: 50%;
}

.price-card__total {
  display: flex;
  justify-content: space-between;
  font-size: var(--font-size-medium);
  font-weight: 600;
  margin-bottom: 8rem;
  margin-top: 1rem;
}

.price-card p {
  margin-bottom: 0.2rem;
}

/* REGISTER */
.authpages {
  width: 90%;
}

.register__form {
  background-color: #ffffff;
  padding: 2rem 3rem;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
}

.register-width {
  /* width: 70%; */
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}

/* MOVIES CLUSTER */
.movies-cluster {
  padding: 0 !important;
}

body .pricing-section div .col-2 {
  padding-left: 0;
  padding-right: 0;
}

.movie-cluster__single img {
  width: 100%;
  max-height: 300px;
  height: 100%;
}

/* Footer */
.footer-top {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: var(--font-size-medium);
}
.footer-top span,
.footer-top span img {
  margin-right: 2rem;
  /* font-weight: 00; */
  font-size: var(--font-size-medium);
}
.line {
  border-top: 1px solid rgba(196, 196, 196, 0.37);
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-size: var(--font-size-tiny);
}

.footer-item {
  display: flex;
  justify-content: center;
}

.footer-item ul li {
  display: inline-block;
  list-style: none;
  color: #ffffff;
}

.footer-item ul li:not(:last-child) {
  padding-right: 15px;
}

.footer-item ul li a,
.footer-item ul li a:hover {
  text-decoration: none;
  color: #ffffff;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}
body .red {
  color: #ff0000 !important;
}
body .grey2 {
  color: #757575 !important;
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    right: 0;
    font-size: 16px;
    width: 210px;
    padding: 20px 0;
  }
}

div#navbarResponsive .nav-item {
  text-align: left !important;
}

body .navbar-collapse {
  text-align: center;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  font-size: 16px;
}

.dropdown-item {
  white-space: pre-line;
}
.dropdown-item span {
  display: block;
  padding-bottom: 10px;
}

.footer-item__menu-item {
}

.footer-item__menu-item:not(:last-child) {
  padding-right: 30px;
}

.footer-item__menu-item a:link,
a:visited,
body a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #74aa50;
  text-decoration: none;
}

.topnav {
  background-color: #000000;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 2px 10px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  color: var(--orange);
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #4caf50;
  color: white;
}

/* Right-aligned section inside the top navigation */
.topnav-right {
  float: right;
}

.navbar-collapse.collapse.in {
  display: block !important;
}

.carouselImage {
  /* width: 100vh !important; */
  object-fit: cover !important;
}

.owl-carousel .owl-stage-outer {
  /* overflow: inherit !important; */
}

a.movies-link span {
  font-size: 16px;
  padding-top: 6px;
  color: rgba(255, 255, 255, 0.6);
  transition: all 200ms ease 0s;
}

a.movies-link span:hover {
  transform: scale(1.2);
  color: rgb(255, 255, 255);
}

.modal-video-inner iframe {
  width: 700px;
  height: 500px;
}

/*** TERMS  ***/
.terms {
}

.terms h2 {
  margin: 2rem 0;
}

.terms h3 {
  font-family: var(--extrabold);
}

.terms p p {
  margin-bottom: 2rem;
}

/*** FAQs ***/
.faq-background-text p {
  font-size: 16px;
  font-family: "Avenir-Medium";
  color: #dfdfdf;
}
.layer {
  background: rgba(27, 57, 7, 0.53);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.faq-background-text {
  text-align: center;
  color: white;
  margin-top: 10rem;
}

.accordion {
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  font-size: 20px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
  font-family: "Avenir-Medium";
}

.active,
.accordion:hover {
  background-color: transparent;
}

.accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.accordionBody {
  margin-top: 80px;
}

.panel {
  padding: 0 18px;
  background-color: transparent;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  color: #e0e0e0;
  font-size: 16px;
  font-family: "Avenir-Roman";
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  /* padding-bottom:15px; */
}

.voucher {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/*** RESPONSIVENESS **/
@media only screen and (max-width: 767px) {
  .featured_item,
  .news_item {
    margin-bottom: 2rem;
  }
}

@media only screen and (min-width: 800px) {
  .featured_item {
    /* margin-top: -10rem; */
  }

  .caption {
    margin-left: 40px;
  }
}

/* Mobile Screen */
@media only screen and (max-width: 530px) {
  /* .movie__others, .other-m {
    display: none;
  } */
  .container {
    padding-right: 5px;
    padding-left: 5px;
  }
  .carousel-caption,
  .footer-item,
  .singlemovie-header {
    display: none;
  }

  .navbar-brand {
    /* padding-left: 0px;
    margin-left: -30px; */
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .movie__ticket-summary {
    margin-top: 2rem;
  }

  .ticket-buyer__radio span {
    display: block;
  }

  .ticket-buyer__details {
    margin-bottom: 1.6rem;
  }

  .ticket-buyer__type {
    margin-bottom: -0.2rem;
  }

  .ticket-md-subscription {
    order: -1;
  }

  .buy-ticket-md-section {
    order: 1;
  }

  .other-movies-section {
    display: none;
  }

  .showing-span {
    visibility: hidden;
  }

  .showing-days {
    overflow-x: scroll;
    display: block;
    width: 100%;
  }

  .day-left {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .day-left::-webkit-scrollbar {
    display: none;
  }
  .tab-content {
    margin-top: 2rem;
  }
}

@media (max-width: 500px) {
  .carousel-item {
    max-height: 400px;
  }
  .carousel-indicators {
    bottom: 0rem !important;
  }
  .featured-slides {
    display: none;
  }
}

.introjs-tooltiptext {
  color: #020d18;
}

html {
  scroll-behavior: smooth;
}

.scroll-down {
	position: absolute;
	bottom: 120px;
	display: block;
	text-align: center;
	font-size: 20px;
	z-index: 100;
	text-decoration: none;
	text-shadow: 0;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
  z-index: 9;
  left: 49%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
	-webkit-animation: fade_move_down 4s ease-in-out infinite;
	-moz-animation:    fade_move_down 4s ease-in-out infinite;
  animation:         fade_move_down 4s ease-in-out infinite;
}

@media only screen and (max-width: 600px) {
  .scroll-down {
    left: 47%;
    bottom: 180px;
  }
}


/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-10px) rotate(45deg); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,10px) rotate(45deg); opacity: 0; }
}
