#ticket-details-form .y-invalid{
    outline: 1px solid red;
}

@media (min-width: 576px) {
    .modal-dialog{
        max-width: 70% !important;
    }

    .modal-content{
        border: none !important;
    }
}