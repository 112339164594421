/* REGULAR */
@font-face {
  font-family: "BioSansRegular";
  src: url("/fonts/biosans-regular-webfont.woff2") format("woff2"),
    url("/fonts/biosans-regular-webfont.woff") format("woff"),
    url("/fonts/biosans-regular-webfont.ttf") format("truetype");
}

/* LIGHT */
@font-face {
  font-family: "BioSansLight";
  src: url("/fonts/biosans-light-webfont.woff2") format("woff2"),
    url("/fonts/biosans-light-webfont.woff") format("woff"),
    url("/fonts/biosans-light-webfont.ttf") format("truetype");
}

/* BOLD */
@font-face {
  font-family: "BioSansBold";
  src: url("/fonts/bio_sans_bold-webfont.woff2") format("woff2"),
    url("/fonts/bio_sans_bold-webfont.woff") format("woff"),
    url("/fonts/bio_sans_bold-webfont.tff") format("truetype");
}

/* SEMI BOLD */
@font-face {
  font-family: "BioSansSemiBold";
  src: url("/fonts/biosans-semibold-webfont.woff2") format("woff2"),
    url("/fonts/biosans-semibold-webfont.woff") format("woff");
}

/* EXTRA LIGHT */
@font-face {
  font-family: "BioSansExtraLight";
  src: url("/fonts/biosans-extralight-webfont.woff2") format("woff2"),
    url("/fonts/biosans-extralight-webfont.woff") format("woff");
}

/* EXTRA BOLD */
@font-face {
  font-family: "BioSansExtraBold";
  src: url("/fonts/biosans-extrabold-webfont.woff2") format("woff2"),
    url("/fonts/biosans-extrabold-webfont.woff") format("woff");
}

.medium-bold {
  font-size: 500;
}
/* regular
italic

PDP:
bold
body italic
semibold
semibold italic
extrabold
extrabold italic

light
light italic

extralight italic
extralight */
