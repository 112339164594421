.y-loader{
    /*background-color: rgba(250, 235, 215, 0.747);*/
    display: block;
    /* position: absolute;
    top: 40%;
    left: 40%; */

    /* position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: #30352e;
    z-index: 1000000; */

    position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
  overflow: hidden;
  background: rgba(10, 10, 10, 0.541);
}
.y-loader div{
    /* width: 60%;
    height: auto;
    margin: 13% auto;
    padding: 10px;
    position: relative;
    */
    text-align: center; 
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    /* width: 100px;
    height: 100px; */
}
.y-loader-hidden{
    display: none;
}
.y-loader-text{
    color: '#fff';
    text-align: center;
}