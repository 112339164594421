.react-autosuggest__suggestions-list {
  list-style: none;
}
.react-autosuggest__suggestion {
  padding: 0.6em;
  border-bottom: 1.5px solid #74aa50;
}

.react-autosuggest__suggestion div a {
  color: #fff;
}
div#react-autowhatever-1 div span {
  padding-left: 28px;
}
body .react-autosuggest__suggestion div a:hover {
  color: #74aa50;
}

ul.react-autosuggest__suggestions-list {
  padding-left: 20px !important;
}
/*
react-autosuggest__suggestions-list
*/
